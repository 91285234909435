<template>
    <div id="about_group">
        <div class="card p-4">
            <h2 class="mb-2 fs-18 fw-600">Tentang Group Ini</h2><hr><br>
            <h3 class="title mb-2"><Icon icon="ci:file-blank-fill" /> ABOUT</h3>
            <p><strong>Description</strong></p>
            <p>{{ detail.description }}</p><br>
            <p><strong>Reason</strong></p>
            <p>{{ detail.reason }}</p><br>
            <p><strong>Topics</strong></p>
            <p>{{ detail.interested_topics }}</p><br>
            <div v-if="detail.privacy === 'private'">
                <h3 class="title mb-2"><Icon icon="uis:lock-alt" /> Private</h3>
                <p>Hanya anggota yang bisa melihat siapa anggota grup ini dan apa yang diposting</p>
            </div>
            <div v-if="detail.privacy === 'public'">
                <h3 class="title mb-2"><Icon icon="uis:lock-open-alt" /> Public</h3>
                <p>Semua orang bisa melihat siapa anggota grup ini dan apa yang diposting</p>
            </div>
            <div v-if="detail.privacy === 'unlisted'">
                <h3 class="title mb-2"><Icon icon="ci:hide" /> Unlisted</h3>
                <p>Orang yang diundang bisa melihat siapa anggota grup ini dan apa yang diposting</p>
            </div>
            <br>
            <h3 class="title mb-2"><Icon icon="clarity:group-solid" /> {{ detail.total_member }} Members</h3>
            <p>Anda dapat mengundang anggota baru, dengan persetujuan admin</p>
            <br>
            <h3 class="title mb-2"><Icon icon="ant-design:clock-circle-outlined" /> Riwayat</h3>
            <p>Grup dibuat pada {{ formatDate(detail.created_at) }}</p>
        </div>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    import { mapState } from 'vuex'
    import moment from 'moment'

    export default {
        name: 'about_detail',
        components: {
            Icon
        },
        computed: mapState('groups', {
            detail: state => state.selected.data,
            loading: state => state.selected.loading
        }),
        method: {
            formatDate (value) {
                return moment(String(value)).format('MM/DD/YYYY')
            }
        }
    }
</script>
